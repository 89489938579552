// @flow strict
import React, { Component } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled, { css } from 'styled-components';
import { FaSync } from 'react-icons/fa';

const Container = styled.div`
  margin: 10px 0px;
  margin-bottom: 1.625rem
`;

const SubscribeButton = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  :hover, :focus {
    outline: none;
  }

  background-color: ${props => props.isSubscribed ? "#b0d9f2" : '#C7E9BA' }; 
  border: ${props => props.isSubscribed ? '1px solid #85c8f1' : '1px solid #a8e492' };
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px;
`;

const Description = styled.div`
  color: #888888;
`;

const FormArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ElementWrapper = styled.div`
  width: 100%;
  margin: 10px;
`;

const InputBox = styled.input`
  font-size: 14px;
  padding: 6px;
  width: 100%;
  outline: none;

  :hover, :focus {
    outline: none;
    width: 100%;
  }
`;

const ElementsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 10px;
`;

const FaSyncWrapper = styled.div`
  margin: 0px 10px;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const EmojiWrapper = styled.span`
  margin: 0px 15px;
  font-size: 20px;
`;


class Newsletter extends Component {
  constructor(props){
    super(props);

    this.state = {
      email : "",
      isSubscribed: false,
      isLoading: false
    }
  }

  handleOnChange = (event) => {
    const email = event.target.value;
    this.setState({
      email
    })
  }

  handleSubmitButton = async (event) => {
    event.preventDefault();
    const email = this.state.email;

    this.setState({
      isLoading: true
    });

    const result = await addToMailchimp(email);

    if (result.result == "success"){
      this.setState({
        isSubscribed: true,
        isLoading: false
      });
    }
  }

  render(){
    const { isSubscribed, isLoading } = this.state;
    return (
      <Container>
        <Title>Newsletter 📰</Title>
        <Description>Remain in sync with latest content</Description>
        <FormArea>
          <ElementWrapper>
            <InputBox value={this.state.email} onChange={this.handleOnChange} placeholder={"bruce.wayne@batmail.com"} />
          </ElementWrapper>
          <ElementsWrapper>
            <SubscribeButton onClick={this.handleSubmitButton} isSubscribed={isSubscribed}>
              {
                isSubscribed ? 'Subscribed' : 'Subscribe'
              }
            </SubscribeButton>
            {
              isLoading ? (
                <FaSyncWrapper>
                <FaSync style={{ 'animation': 'rotation 2s infinite linear', 'fontSize': '14px' }}/>
              </FaSyncWrapper>
              )
              :
              null
            }
            {
              isSubscribed ? (
                <EmojiWrapper>🙂</EmojiWrapper>
              )
              :
              null
            }
          </ElementsWrapper>
        </FormArea>
      </Container>
    )   
  }
}

export default Newsletter;
