// @flow strict
import React from 'react';
import { withPrefix, Link } from 'gatsby';
import styles from './Author.module.scss';
import styled from 'styled-components';
import Contacts from '../Contacts';

const Position = styled.span`
  font-size: 15px;
`;

type Props = {
  author: {
    name: string,
    bio: string,
    position: position,
    photo: string
  },
  isIndex: ?boolean
};

const ContactsArea = styled.div`
  display: flex;
  align-items: center;
`;

const Author = ({ author, isIndex }: Props) => {
  return (
    <div className={styles['author']}>
      <ContactsArea>
        <Link to="/">
          <img
            src={withPrefix(author.photo)}
            className={styles['author__photo']}
            width="75"
            height="75"
            alt={author.name}
          />
        </Link>
        <Contacts contacts={author.contacts} />
      </ContactsArea>

      { isIndex === true ? (
        <h1 className={styles['author__title']}>
          <Link className={styles['author__title-link']} to="/">{author.name}</Link>
        </h1>
      ) : (
        <h2 className={styles['author__title']}>
          <Link className={styles['author__title-link']} to="/">{author.name}</Link>
        </h2>
      )}
      <Position>{author.position}</Position>
      <p className={styles['author__subtitle']}>{author.bio}</p>
    </div>
  )
}

export default Author;
